<template>
  <div>
    <NewFormOfPaymentDialog
      v-if="showNewFormOfPaymentDialog"
      @closed="closed"
      @created="created"
      :fop="formOfPayment"
    />
    <ExtendedView :tabs="tabs">
      <template v-slot:list="{ item }">
        <FormOfPaymentList
          v-if="item.key === 'active'"
          :forms-of-payment="activeFormsOfPayment"
          :load-items-callback="getFormsOfPayment"
          :call-to-actions="callToActions"
          :fixed-filters="activeFilters"
          @selectFormOfPayment="selectFormOfPayment"
        />
        <FormOfPaymentList
          v-if="item.key === 'inactive'"
          :forms-of-payment="inactiveFormsOfPayment"
          :load-items-callback="getFormsOfPayment"
          :call-to-actions="callToActions"
          :fixed-filters="inactiveFilters"
          @selectFormOfPayment="selectFormOfPayment"
        />
      </template>
      <template slot="profile">
        <FormOfPaymentProfile
          v-if="selectedFormOfPayment !== null"
          :form-of-payment="selectedFormOfPayment"
          @updated="selectedFormOfPayment = null"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
	import ExtendedView from "@/components/common/ExtendedView"
  import FormOfPaymentList from "@/components/formsOfPayment/FormOfPaymentList"
  import NewFormOfPaymentDialog from "@/components/formsOfPayment/NewFormOfPaymentDialog"
  import FormOfPaymentProfile from "@/components/formsOfPayment/FormOfPaymentProfile"

  export default {
    name: 'FormsOfPayment',
    components: {
      NewFormOfPaymentDialog,
      FormOfPaymentList,
      FormOfPaymentProfile,
			ExtendedView
    },
    data() {
      return {
        callToActions: [
          {
            title: this.$t('addGiftcard'),
            callback: this.toggleNewFormOfPaymentDialog
          }
			  ],
        showNewFormOfPaymentDialog: false,
        selectedFormOfPayment: null,
        formOfPayment: {
          active: true,
          amount: null,
          customer: null,
          expiryMonth: (new Date().getMonth()).toString(),
          expiryYear: (new Date().getFullYear() + 2).toString(),
          paymentMethod: 'giftcard',
          paymentMethodInstance: null,
          remoteID: ""
        },
        activeFilters: [
          {
            key: 'active',
            value: true,
            operator: 'equals',
            type: 'boolean'
          }
        ],
        inactiveFilters: [
          {
            key: 'active',
            value: false,
            operator: 'equals',
            type: 'boolean'
          }
        ],
      }
    },
    methods: {
      getFormsOfPayment() {
        return this.$store.dispatch('getFormsOfPayment', {})
      },
      selectFormOfPayment(formOfPayment) {
        this.selectedFormOfPayment = formOfPayment
      },
      exportFormsOfPayment() {
        this.$store.commit('updateExportObjects', this.formsOfPayment)
      },
      toggleNewFormOfPaymentDialog() {
        this.showNewFormOfPaymentDialog = !this.showNewFormOfPaymentDialog
      },
      closed() {
        this.showNewFormOfPaymentDialog = !this.showNewFormOfPaymentDialog
        this.formOfPayment = {
          active: true,
          amount: null,
          customer: null,
          expiryMonth: (new Date().getMonth()).toString(),
          expiryYear: (new Date().getFullYear() + 2).toString(),
          paymentMethod: 'giftcard',
          paymentMethodInstance: null,
          remoteID: ""
        }
      },
      created() {
        this.$store.commit('updateAppBarTabs', this.tabs)
      }
    },
    watch: {
      formsOfPayment: function() {
        this.$store.commit('updateAppBarTabs', this.tabs)
      }
    },
    computed: {
      tabs() {

        const tabs = [
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: 0
            }
          },
          {
            title: this.$t('inactive'),
            key: 'inactive',
            badge: {
              color: 'red',
              content: 0
            }
          }
        ]

        if(this.activeFormsOfPayment !== null) {
          tabs[0].badge.content = this.activeFormsOfPayment.length
        }

        if(this.inactiveFormsOfPayment !== null) {
          tabs[1].badge.content = this.inactiveFormsOfPayment.length
        }

        return tabs
      },
      selectedAppBarTab() {
        return this.$store.state.selectedAppBarTab
      },
      formsOfPayment() {
        return this.$store.state.formsOfPayment
      },
      activeFormsOfPayment() {

        if(this.formsOfPayment === null || this.formsOfPayment === undefined) {
          return null
        }

        return this.formsOfPayment.filter(formOfPayment => {
          return formOfPayment.active
        })
      },
      inactiveFormsOfPayment() {

        if(this.formsOfPayment === null || this.formsOfPayment === undefined) {
          return null
        }

        return this.formsOfPayment.filter(formOfPayment => {
          return !formOfPayment.active
        })
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    },
    created() {
      this.$store.commit('updateAppBarExport', {
        callback: this.exportFormsOfPayment
      })
    }
  }
</script>
